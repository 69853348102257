export default {
  props: {
    //模板批次ID
    ShowTitle: {
      type: String,
      default: ""
    },
    Val: {
      type: Number,
      default: 0
    },
    MaxValue: {
      type: Number,
      default: 3
    },
    //是否显示不考虑 清空按钮
    // IsshowSet: {
    //     type: Boolean,
    //     default: true
    // },
    //是否只读 只读的也不显示
    Readonly: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      favcolors: ['#F56C6C', '#F56C6C', '#F56C6C'],
      iconClasses: ['el-icon-aixin', 'el-icon-aixin', 'el-icon-aixin'],
      rates: this.Val,
      show: true
    };
  },

  watch: {
    Val(val1, val2) {
      this.rates = this.Val;
    }

  },
  methods: {
    reload() {
      this.show = false;
      this.$nextTick(function () {
        this.show = true;
      });
    },

    handleChange(val) {
      this.$nextTick(() => {
        this.rates = val;
        this.$emit("OnChange", val); //console.log("当前值：" + this.rates);
      });
    }

  },
  computed: {
    ShowVal() {
      if (this.rates <= 0) return 0;
      return this.rates;
    }

  }
};