import {
	createApp
} from 'vue'
import App from './App.vue'
var app = createApp(App);

import './assets/css/common.css'

import util from './util/util.js'
app.config.globalProperties.$util = util

import globle from './util/globle.js'
app.config.globalProperties.$globle = globle

// Toast
import { Toast } from 'vant';
import 'vant/es/toast/style';
app.use(Toast);
app.config.globalProperties.$message = Toast;
// Dialog
import { Dialog } from 'vant';
import 'vant/es/dialog/style';
app.use(Dialog);
app.config.globalProperties.$dialog = Dialog;


// Notify
import { Notify } from 'vant';
import 'vant/es/notify/style';
app.use(Notify);

// ImagePreview
import { ImagePreview } from 'vant';
import 'vant/es/image-preview/style';
// app.use(ImagePreview);

// this.$message.success('成功文案');
// this.$message.fail('失败文案');
import { Badge } from 'vant';
app.use(Badge);
import { Stepper } from 'vant';
app.use(Stepper);
import { Rate } from 'vant';
app.use(Rate);
import { Button } from 'vant';
app.use(Button);
import { Tabbar, TabbarItem } from 'vant';
app.use(Tabbar);
app.use(TabbarItem);
import { NavBar } from 'vant';
app.use(NavBar);
import { Icon } from 'vant';
app.use(Icon);
import { Popup } from 'vant';
app.use(Popup);
import { Swipe, SwipeItem } from 'vant';
app.use(Swipe);
app.use(SwipeItem);
import { Image as VanImage } from 'vant';
app.use(VanImage);
import { Grid, GridItem } from 'vant';
app.use(Grid);
app.use(GridItem);
import { Col, Row } from 'vant';
app.use(Col);
app.use(Row);
import { DropdownMenu, DropdownItem } from 'vant';
app.use(DropdownMenu);
app.use(DropdownItem);
import { Checkbox, CheckboxGroup } from 'vant';
app.use(Checkbox);
app.use(CheckboxGroup);
import { RadioGroup, Radio } from 'vant';
app.use(Radio);
app.use(RadioGroup);
import { Search } from 'vant';
app.use(Search);
import { Sticky } from 'vant';
app.use(Sticky);
import { List } from 'vant';
app.use(List);
import { Sidebar, SidebarItem } from 'vant';
app.use(Sidebar);
app.use(SidebarItem);
import { Empty } from 'vant';
app.use(Empty);
import { Pagination } from 'vant';
app.use(Pagination);
import { Form, Field, CellGroup } from 'vant';
app.use(Form);
app.use(Field);
app.use(CellGroup);
import { Collapse, CollapseItem } from 'vant';
app.use(Collapse);
app.use(CollapseItem);
import { Slider } from 'vant';
app.use(Slider);
import { ActionSheet } from 'vant';
app.use(ActionSheet);
import { DatetimePicker } from 'vant';
app.use(DatetimePicker);
import { Divider } from 'vant';
app.use(Divider);
import { NoticeBar } from 'vant';
app.use(NoticeBar);
import { Tag } from 'vant';
app.use(Tag);
import { Calendar } from 'vant';
app.use(Calendar);
// app.config.globalProperties.$confirm = ElMessageBox.confirm

import router from './router'
app.use(router);

import store from './store/index.js'
app.use(store)
app.config.globalProperties.$store = store


// js通用方法
import api from './api/api.js'
app.config.globalProperties.$api = api

import base from './assets/script/common.js'
import http from './api/http.js'
app.config.globalProperties.$http = http
app.config.globalProperties.$base = base

import VueCookies from 'vue-cookies'
app.config.globalProperties.$cookies = VueCookies

import favrate from "./components/cp_fav.vue"
app.component('favrate', favrate)

import Vuelazyload from 'vue-lazyload'
app.use(Vuelazyload, {
       error: require('../public/imgs/gby.png'),
       loading: require('../public/imgs/gby.png')
})

// 时间处理
import Moment from 'moment'
import 'moment/dist/locale/zh-cn'
Moment.locale('zh-cn')
app.config.globalProperties.$moment = Moment
app.mount("#app");
