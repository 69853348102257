import axios from 'axios'
import store from '../store/index'
import {
	useRouter,
	useRoute
} from 'vue-router'
const router = useRouter();
axios.defaults.timeout = 50000;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

import {
	Toast as Message
} from 'vant';
import 'vant/es/toast/style';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// if (process.env.NODE_ENV == 'development') {
// 	axios.defaults.baseURL = 'https://localhost:44374/';
// } else if (process.env.NODE_ENV == 'alpha') {
// 	axios.defaults.baseURL = 'http://devapi.gaobaoyun.com/';
// } else if (process.env.NODE_ENV == 'production') {
// 	axios.defaults.baseURL = 'http://api.gaobaoyun.com/';
// 	// axios.defaults.baseURL = 'http://119.45.101.116:1999/';
// }
let ipAddress = axios.defaults.baseURL;
axios.interceptors.request.use((config) => {
	return config;
}, (error) => {
	return Promise.reject(error);
});

axios.interceptors.response.use((res) => {

	closeLoading();

	checkResponse(res);

	return Promise.resolve(res);
}, (error) => {
	closeLoading();
	let httpMessage = '';
	if (error.response) {
		if (error.response.status == '401') {
			if (error.response.data && error.response.data.code == 401) {
				if (!localStorage.getItem('user')) {
					Message.fail('登陆已过期');
				}
				toLogin();
				return;
			}

		}
		if (error.response.status == '404') {
			httpMessage = "未找到请求地址";
		} 
		else if (error.response.status == '409') {
			httpMessage='该账号已在其它地方登录，请重新登录！'
			Message.fail(httpMessage);
			setTimeout(toLogin,2000)
			// toLogin();
			return
		}
		else if (error.response.data && error.response.data.message) {
			httpMessage = error.response.data.message;
		}
	} else {
		httpMessage = '服务器处理异常'
	}
	redirect(httpMessage);
	return Promise.reject(error.response || {}, httpMessage);
});

function closeLoading() {
	store.commit('set_loadingStatus', false);
}

function checkResponse(res) {
	//debugger
	//刷新token
	if (!res.headers) {
		if (res.getResponseHeader("sxt_exp") == "1") {
			replaceToken();
		}
	} else if (res.headers.sxt_exp != null && res.headers.sxt_exp == "1") {
		replaceToken();
	}
}

const _Authorization = 'Authorization';

function showLoading(loading) {
	store.commit('set_loadingStatus', true);
}

function getToken() {
	return "Bearer " + store.getters.getToken();
}

/*
  url
  params请求后台的参数,如：{name:123,values:['a','b','c']}
  loading是否显示遮罩层,可以传入true.false.及提示文本
  config配置信息,如{timeout:3000,headers:{token:123}}
*/
function post(url, params, loading, config) {
	showLoading(loading);
	axios.defaults.headers[_Authorization] = getToken();
	var refer = encodeURIComponent(window.location.href)
	axios.defaults.headers["fullreferer"] = refer
	return new Promise((resolve, reject) => {
		axios.post(url, params, config)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err && err.data && err.data.message ? err.data.message : '服务器处理异常');
			})
			.catch((error) => {
				reject(error)
			})
	})
}

//=true异步请求时会显示遮罩层,=字符串，异步请求时遮罩层显示当前字符串
function get(url, param, loading, config) {
	showLoading(loading);
	axios.defaults.headers[_Authorization] = getToken();
	var refer = encodeURIComponent(window.location.href)
	axios.defaults.headers["fullreferer"] = refer
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params: param
			}, config)
			.then(response => {
				resolve(response.data)
			}, err => {
				reject(err)
			})
			.catch((error) => {
				reject(error)
			})
	})
}




function createXHR() {
	if (XMLHttpRequest) {
		return new XMLHttpRequest();
	}
	if (ActiveXObject) {
		if (typeof arguments.callee.activeXString != "string") {
			var versions = [
				"MSXML2.XMLHttp.6.0",
				"MSXML2.XMLHttp",
				"MSXML2.XMLHttp.3.0"
			];
			for (var i = 0; i < versions.length; i++) {
				try {
					new ActiveXObject(versions[i]);
					arguments.callee.activeXString = versions[i];
					break;
				} catch (e) {
					console.log(e);
				}
			}
		}
		return new ActiveXObject(arguments.callee.activeXString);
	}
}

function redirect(responseText, message) {
	try {
		let responseData = typeof responseText == 'string' ? JSON.parse(responseText) : responseText;
		if ((responseData.hasOwnProperty('code') && responseData.code == 401) ||
			(responseData.data && responseData.data.code == 401)) {
			closeLoading();
			toLogin();
		} else {
			if (message) {
				Message.fail(message);
			}
		}
	} catch (error) {
		console.log(error);
		Message.fail(message);
	}
}


function toLogin() {
	store.commit("clearUserInfo");
	var params = ''
	var val = getQueryString(window.location.href, 'orgid')
	if (val)
		params = '?orgid=' + val;
	if (window.location.hash) {
		store.commit("clearUserInfo");
		window.location.href = window.location.origin + '/#/home' + params
		return
	}
	window.location.href = window.location.origin + '/home' + params
}

function getQueryString(url, name) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url, name) || [, ""])[1]
		.replace(/\+/g, '%20')) || null
}

//动态刷新token
function replaceToken() {
	ajax({
		url: "/Sys/Account/replaceToken",
		param: {},
		json: true,
		success: function(x) {
			if (x.code == 1) {
				//let userInfo = store.getters.getUserInfo();
				//userInfo.token = x.data;
				//store.commit('setUserInfo', userInfo);
				store.commit('set_token', x.data.token);
			} else {
				console.log(x.message);
				toLogin();
			}
		},
		errror: function(ex) {
			console.log(ex);
			toLogin();
		},
		type: "post",
		async: false
	});


}

function ajax(param) {
	let httpParam =
		Object.assign({
			url: '',
			headers: {},
			param: {},
			json: true,
			success: function() {},
			errror: function() {},
			type: 'post',
			async: true
		}, param);

	httpParam.url = axios.defaults.baseURL + httpParam.url.replace(/\/?/, '');
	httpParam.headers[_Authorization] = getToken();
	var xhr = createXHR();
	xhr.onreadystatechange = function() {
		if (xhr.status == 403 || xhr.status == 401) {
			redirect(xhr.responseText);
			return;
		}
		checkResponse(xhr);
		if (xhr.readyState == 4 && xhr.status == 200) {
			httpParam.success(httpParam.json ? JSON.parse(xhr.responseText) : xhr.responseText);
			return;
		}
		if (xhr.status != 0 && xhr.readyState != 1) {
			httpParam.errror(xhr);
		}
	};
	//初始化请求
	xhr.open(
		httpParam.type,
		httpParam.url,
		httpParam.async
	);
	xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
	for (const key in httpParam.headers) {
		xhr.setRequestHeader(key, httpParam.headers[key]);
	}
	let dataStr = '';
	for (const key in httpParam.param) {
		dataStr += key + "=" + httpParam.param[key];
	}
	try {
		xhr.send(dataStr);
	} catch (error) {
		toLogin();
	}
}

ajax.post = function(url, param, success, errror) {
	ajax({
		url: url,
		param: param,
		success: success,
		error: errror,
		type: 'post'
	})
}
ajax.get = function(url, param, success, errror) {
	ajax({
		url: url,
		param: param,
		success: success,
		error: errror,
		type: 'get'
	})
}
export default {
	post,
	get,
	ajax,
	ipAddress,
}
