import {
	createStore
} from "vuex"
import createPersistedState from "vuex-persistedstate";
export default createStore({
	plugins: [createPersistedState()],
	state: {
		token: '',
		remember: false, //是否记住密码
		menus: [],
		flatmenus: [],
		permission_Operate: [], //用户操作权限
		permission_Data: [], //用户数据权限
		isLoading: false,
		userInfo: null,
		rootOrgList: [],
		currentOrgID: '',
		currentAppID: '',
		currentOrgName: '',
		currentAppName: '',
		currentUrl: '',
		homeUrl: '',
		xuankeType: '',
		GKModel: '',
		archiveActive: '高考基本信息',
		zhiYuanIndex: 0,
		zhiYuanDetail: [],
		loginToPath: '',
		showCheckSbj: false,
		webStyle: '',
		clientHeight: 0,
		logoUrl: '',
		newsType: 1,
		scoreAndOrder: {},
		recordIndex: 1,
		ZyPiciData: [],
		loadingStatus:false,
		stuTags:{},
		userSetArea: null,
		visitorArea: null,
		mobiles:[],
		tifenLanMuIndex:0,
		isShowCode:true,
		isUpCard:0,
		OrgID:'',
		orgExtModel:{},
	},
	mutations: {
		SetOrgExtModel(state, data) {
			state.orgExtModel = data;
		},
		SetOrgID(state, data) {
			state.OrgID = data;
		},
		set_IsUpCard(state, data) {
			state.isUpCard = data;
		},
		set_ShowCode(state, data) {
			state.isShowCode = data;
		},
		SetCurrMobiles(state, data) {
			state.mobiles = data;
		},
		set_UserSetArea(state, data) {
			state.userSetArea = data;
		},
		set_VisitorArea(state, data) {
			state.visitorArea = data;
		},
		set_StuTags(state, data) {
			state.stuTags = data;
		},
		set_loadingStatus(state, data){
			state.loadingStatus = data;
		},
		set_isTianBaoTime(state, data) {
			state.isTianBaoTime = data;
		},
		set_recordIndex(state, data) {
			state.recordIndex = data;
		},
		set_ScoreAndOrder(state, data) {
			state.scoreAndOrder = data;
		},
		set_OrgNewsType(state, data) {
			state.newsType = data;
		},
		//store 存储志愿批次数据
		set_ZyPiciData(state, data) {

			if (state.ZyPiciData == undefined)
				state.ZyPiciData = [];
			let index = state.ZyPiciData.findIndex(c => c.ZYID == data.ZYID && c.AreaID == data.AreaID);
			if (index < 0) state.ZyPiciData.push(data);
			else {
				state.ZyPiciData[index] = data;
			}
		},
		//清空本地志愿数据
		clear_ZyPiciData(state, obj) {
			if (state.ZyPiciData == undefined)
				state.ZyPiciData = [];
			let index = state.ZyPiciData.findIndex(c => c.ZYID == obj.ZYID && c.AreaID == obj.AreaID);
			if (index >= 0) {
				state.ZyPiciData.splice(index, 1)
			}
		},
		set_logoUrl(state, data) {
			state.logoUrl = data;
		},
		set_clientHeight(state, data) {
			state.clientHeight = data;
		},
		set_webStyle(state, data) {
			state.webStyle = data;
		},
		set_loginToPath(state, data) {
			state.loginToPath = data;
		},
		set_showCheckSbj(state, data) {
			state.showCheckSbj = data;
		},
		set_zhiYuanIndex(state, data) {
			state.zhiYuanIndex = data;
		},
		set_TiFenLanMuIndex(state, data) {
			state.tifenLanMuIndex = data;
		},
		set_zhiYuanDetail(state, data) {
			state.zhiYuanDetail = data;
		},
		set_archiveActive(state, data) {
			state.archiveActive = data;
		},
		set_XuanKeType(state, data) {
			state.xuankeType = data;
		},
		set_GKModel(state, data) {
			state.GKModel = data;
		},
		set_homeUrl(state, data) {
			state.homeUrl = data;
		},
		set_currentUrl(state, data) {
			state.currentUrl = data;
		},
		set_currentOrgID(state, data) {
			state.currentOrgID = data;
		},
		set_currentAppID(state, data) {
			state.currentAppID = data;
		},
		set_currentOrgName(state, data) {
			state.currentOrgName = data;
		},
		set_currentAppName(state, data) {
			state.currentAppName = data;
		},
		set_rootOrgList(state, data) {
			state.rootOrgList = data;
		},
		set_token(state, data) {
			state.token = data;
		},
		set_flatmenus(state, data) {
			state.flatmenus = data;
		},
		set_menus(state, data) {
			state.menus = data;
		},
		set_permission_Operate(state, data) {
			state.permission_Operate = data;
		},
		set_permission_Data(state, data) {
			state.permission_Data = data;
		},
		set_remember(state, data) {
			state.remember = data;
		},
		updateLoadingState(state, data) {
			state.isLoading = data
		},
		setUserInfo(state, data) {
			state.userInfo = data;
		},
		clearUserInfo(state) {
			state.token = '';
			state.userInfo = null;
			// for (let i in state) {
			// 	delete state[i]
			// }
			state.stuTags = {};
		}
	},
	getters: {
		getOrgExtModel: (state) => () => {
			return state.orgExtModel;
		},
		getOrgID: (state) => () => {
			return state.OrgID;
		},
		getIsUpCard:(state) => () => {
			return state.isUpCard;
		},
		getShowCode: (state) => () => {
			return state.isShowCode;
		},
		GetCurrMobiles: (state) => () => {
			return state.mobiles;
		},
		getUserSetArea: (state) => () => {
			return state.userSetArea;
		},
		getVisitorArea: (state) => () => {
			return state.visitorArea;
		},
		getStuTags:(state) => () => {
			return state.stuTags;
		},
		get_loadingStatus: (state) => () => {
			return state.loadingStatus;
		},
		get_isTianBaoTime:(state)=>()=> {
			return state.isTianBaoTime;
		},
		get_recordIndex: (state) => () => {
			return state.recordIndex;
		},
		get_ScoreAndOrder: (state) => () => {
			return state.scoreAndOrder;
		},
		get_OrgNewsType: (state) => () => {
			return state.newsType;
		},
		getLogoUrl: (state) => () => {
			return state.logoUrl;
		},
		getZhiYuanIndex: (state) => () => {
			return state.zhiYuanIndex;
		},
		getTiFenLanMuIndex: (state) => () => {
			return state.tifenLanMuIndex;
		},
		getClientHeight: (state) => () => {
			return state.clientHeight;
		},
		getWebStyle: (state) => () => {
			return state.webStyle;
		},
		getLoginToPath: (state) => () => {
			return state.loginToPath;
		},
		get_showCheckSbj(state, data) {
			state.showCheckSbj = data;
		},
		getZhiYuanDetail: (state) => () => {
			return state.zhiYuanDetail;
		},
		getZyPiciData: (state) => () => {
			return state.ZyPiciData;
		},
		getArchiveActive: (state) => () => {
			return state.archiveActive;
		},
		getOperate_permission: (state) => (pageCode, code) => {
			let temp = state.permission_Operate.find(x => x.PageCode == pageCode && x.Code == code);
			if (temp != undefined) {
				return temp.Value == 1 ? true : false;
			} else {
				return false;
			}
		},
		getOperate_Data: (state) => (pageCode, code) => {
			var items = [];
			let item = state.permission_Data.find(x => x.Code == code && x.PageCode == pageCode);
			if (item != undefined) {
				items = item.items;
			}
			return items;
		},
		getMenuPermission: (state) => (url) => {
			if (state.flatmenus == null || state.flatmenus.length == 0) {
				return true;
			}
			let item = state.flatmenus.find(x => x.Url == url);
			if (item == undefined) {
				return true;
			} else {
				return false;
			}
		},
		getToken: (state) => () => {
			return state.token;
		},
		isLoading: (state) => () => {
			return state.isLoading;
		},
		getUserInfo: (state) => () => {
			return state.userInfo;
		},
		getCurrentOrgID: (state) => () => {
			return state.currentOrgID;
		},
		getCurrentAppID: (state) => () => {
			return state.currentAppID;
		},
		getCurrentOrgName: (state) => () => {
			return state.currentOrgName;
		},
		getCurrentAppName: (state) => () => {
			return state.currentAppName;
		},
		getCurrentUrl: (state) => () => {
			return state.currentUrl;
		},
		getHomeUrl: (state) => () => {
			return state.homeUrl;
		},
		getXuankeType: (state) => () => {
			return state.xuankeType;
		},
		getGKModel: (state) => () => {
			return state.GKModel;
		},
	},
	actions: {
		onLoading(context, flag) {
			context.commit("updateLoadingState", flag); //调用方式 store.dispatch('onLoading',data)
		}
	}
})
