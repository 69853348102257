import { Dialog } from 'vant';
export default {
  name: 'App',

  data() {
    return {
      showDialogSbj: false
    };
  },

  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },

    GoToSbj() {
      //前往个人中心完善选科/文理
      this.showDialogSbj = false;
      router.push("base");
    }

  },
  watch: {
    "$store.state.showCheckSbj": {
      handler: function (newVal, oldVal) {
        if (newVal == true) {
          this.showDialogSbj = newVal;
          Dialog.confirm({
            title: '提醒',
            message: '您的选科/文理信息未填写，请完善信息后再使用该功能！现在去填写？'
          }).then(() => {
            this.$router.push({
              path: '/base'
            });
          }).catch(() => {});
        }
      }
    }
  },

  mounted() {
    if (!this._isMobile()) {
      var a = document.createElement("a"); //获取pc端

      var url = "";
      this.$http.get("OrgExt/GetOrgMobileOrPCDomain", {
        domain: window.location.host,
        type: "pc"
      }, true).then(res => {
        if (res.code == 1) {
          url = window.location.href.replace(window.location.host, res.data); // url = "http://" + res.data;						

          window.location.href = url;
        } else {
          this.$message.fail(res.msg);
        }
      });
    }
  },

  created() {}

};